import clsx from 'clsx';
import { CommandEmpty } from 'cmdk';
import { ComponentPropsWithoutRef, FC } from 'react';
import { comboboxEmpty } from './search-combobox.css';

export const SearchComboboxEmpty: FC<
  ComponentPropsWithoutRef<typeof CommandEmpty>
> = ({ className, ...props }) => {
  return <CommandEmpty className={clsx(comboboxEmpty, className)} {...props} />;
};
