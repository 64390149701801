import { QueryRef, useReadQuery } from '@apollo/client';
import { SearchComboboxEmpty } from '@synoptic/ui-kit/search-combobox/search-combobox-empty.js';
import { SearchComboboxInput } from '@synoptic/ui-kit/search-combobox/search-combobox-input.js';
import { SearchComboboxList } from '@synoptic/ui-kit/search-combobox/search-combobox-list.js';
import { SearchComboboxLoading } from '@synoptic/ui-kit/search-combobox/search-combobox-loading.js';
import { SearchComboboxRoot } from '@synoptic/ui-kit/search-combobox/search-combobox.js';
import { Suspense } from 'react';
import {
  TypeaheadQueryQuery,
  TypeaheadQueryQueryVariables,
} from '@/__generated__/graphql';
import { DefaultSearchItem } from './default-search-item';
import { useTypeahead } from './use-typeahead';
import { UserSearchItem } from './user-search-item';

const SearchItems = ({
  queryRef,
}: {
  queryRef: QueryRef<TypeaheadQueryQuery, TypeaheadQueryQueryVariables>;
}) => {
  const {
    data: {
      typeahead: { users },
    },
  } = useReadQuery(queryRef);

  return (
    <>{users?.map((user) => <UserSearchItem key={user.id} user={user} />)}</>
  );
};

export type SearchTypeaheadProps = {
  className?: string;
  defaultValue?: string;
  limit?: number;
  inPopover?: boolean;
  open?: boolean;
  onOpenChange?: (v: boolean) => void;
};
export const SearchTypeahead = ({
  className,
  defaultValue,
  limit,
  inPopover = true,
  onOpenChange,
  open,
}: SearchTypeaheadProps) => {
  const { onValueChange, queryRef, value } = useTypeahead({
    defaultValue,
    limit,
  });

  return (
    <SearchComboboxRoot
      shouldFilter={false}
      className={className}
      open={open}
      onOpenChange={onOpenChange}
    >
      <SearchComboboxInput
        value={value}
        onValueChange={onValueChange}
        placeholder="Search"
      />
      <SearchComboboxList inPopover={inPopover}>
        <Suspense fallback={<SearchComboboxLoading />}>
          <SearchComboboxEmpty>
            Try searching for people or keywords
          </SearchComboboxEmpty>
          {value.length > 0 && <DefaultSearchItem query={value} />}
          {queryRef && <SearchItems queryRef={queryRef} />}
        </Suspense>
      </SearchComboboxList>
    </SearchComboboxRoot>
  );
};
