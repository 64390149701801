import { CommandItem } from 'cmdk';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useSearchComboboxContext } from './search-combobox-context';
import { comboboxItem } from './search-combobox.css';
import clsx from 'clsx';

export const SearchComboboxItem: FC<
  ComponentPropsWithoutRef<typeof CommandItem>
> = ({ className, onSelect, ...props }) => {
  const { setListVisible } = useSearchComboboxContext();

  return (
    <CommandItem
      className={clsx(comboboxItem, className)}
      onSelect={(v) => {
        onSelect?.(v);
        setListVisible(false);
      }}
      {...props}
    />
  );
};
