import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.js';
import {
  SearchTypeahead,
  SearchTypeaheadProps,
} from '../search-typeahed/search-typeahead';
import { headerSearch } from './page-header.css';

export const PageHeaderSearch = (
  props: Omit<SearchTypeaheadProps, 'className'>,
) => {
  const isTablet = useIsTablet();

  return isTablet ? (
    <SearchTypeahead {...props} className={headerSearch} />
  ) : null;
};
