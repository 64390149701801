import { SearchComboboxItem } from '@synoptic/ui-kit/search-combobox/search-combobox-item.js';
import { useNavigate } from 'react-router';
import { UserBadgeFragmentFragment } from '@/__generated__/graphql';
import { S3Avatar } from '../s3/user/s3-avatar';
import {
  searchUserAvatar,
  searchUserHandle,
  searchUserInfo,
  searchUserItem,
  searchUserName,
} from './user-search-item.css';

export const UserSearchItem = ({
  user,
}: {
  user: UserBadgeFragmentFragment;
}) => {
  const navigate = useNavigate();

  return (
    <SearchComboboxItem
      className={searchUserItem}
      value={user.username}
      onSelect={() => navigate(`/${user.username}`)}
    >
      <S3Avatar
        className={searchUserAvatar}
        size="small"
        name={user.fullName}
        s3Key={user.avatar?.key}
        userId={user.id}
      />
      <div className={searchUserInfo}>
        <span className={searchUserName}>{user.fullName}</span>
        <span className={searchUserHandle}>{`@${user.username}`}</span>
      </div>
    </SearchComboboxItem>
  );
};
