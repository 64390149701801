import { SearchComboboxItem } from '@synoptic/ui-kit/search-combobox/search-combobox-item.js';
import { useNavigate, useSearchParams } from 'react-router';
import { paths } from '@/routes-utils/paths';
import { defaultSearchItem } from './default-search-item.css';

export const DefaultSearchItem = ({ query }: { query: string }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <SearchComboboxItem
      className={defaultSearchItem}
      onSelect={() => {
        const params = new URLSearchParams({
          q: query,
        });
        const activeSearchType = searchParams.get('t');
        if (activeSearchType) {
          params.append('t', activeSearchType);
        }

        navigate({
          pathname: paths.search,
          search: params.toString(),
        });
      }}
    >
      Search for &quot;{query}&quot;
    </SearchComboboxItem>
  );
};
