import { CommandLoading } from 'cmdk';
import { comboboxItemSkeleton } from './search-combobox.css';
import { Skeleton } from '../skeleton/skeleton';

export const SearchComboboxLoading = () => {
  return (
    <CommandLoading>
      <div className={comboboxItemSkeleton}>
        <Skeleton height={20} width={120} />
      </div>
      <div className={comboboxItemSkeleton}>
        <Skeleton height={20} width={80} />
      </div>
    </CommandLoading>
  );
};
