import clsx from 'clsx';
import { CommandRoot } from 'cmdk';
import { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { useControllableState } from '../utils/use-controllable-state';
import { SearchComboboxContextProvider } from './search-combobox-context';
import { comboboxRoot } from './search-combobox.css';
import { Popover } from '@radix-ui/react-popover';
import { useFocusOutside } from '../utils/use-focus-outside';

export const SearchComboboxRoot: FC<
  ComponentPropsWithoutRef<typeof CommandRoot> & {
    open?: boolean;
    onOpenChange?: (v: boolean) => void;
  }
> = ({ children, shouldFilter, className, open, onOpenChange }) => {
  const [listVisible, setListVisible] = useControllableState({
    value: open,
    onChange: onOpenChange,
    defaultValue: false,
  });

  const contextValue = useMemo(
    () => ({ setListVisible, listVisible }),
    [listVisible, setListVisible],
  );

  const { onBlurCapture, onFocusCapture } = useFocusOutside(() => {
    setListVisible(false);
  });

  return (
    <SearchComboboxContextProvider value={contextValue}>
      <Popover open={listVisible} onOpenChange={setListVisible}>
        <CommandRoot
          onFocusCapture={onFocusCapture}
          onBlurCapture={onBlurCapture}
          className={clsx(comboboxRoot, className)}
          shouldFilter={shouldFilter}
        >
          {children}
        </CommandRoot>
      </Popover>
    </SearchComboboxContextProvider>
  );
};
