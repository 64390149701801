import { CommandList } from 'cmdk';
import { useSearchComboboxContext } from './search-combobox-context';
import { comboboxList, comboboxPopoverList } from './search-combobox.css';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import clsx from 'clsx';
import { PopoverContent, PopoverPortal } from '@radix-ui/react-popover';
import { Wrap } from '../wrap';
import { space } from '../tokens';

const SearchComboboxPopoverContent = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <PopoverPortal>
      <PopoverContent
        sideOffset={space.s4}
        onOpenAutoFocus={(e) => e.preventDefault()}
        onInteractOutside={(e) => {
          if ((e.target as HTMLElement).closest('[cmdk-root]')) {
            e.preventDefault();
          }
        }}
      >
        {children}
      </PopoverContent>
    </PopoverPortal>
  );
};

export const SearchComboboxList = ({
  className,
  inPopover = true,
  ...props
}: ComponentPropsWithoutRef<typeof CommandList> & { inPopover?: boolean }) => {
  const { listVisible } = useSearchComboboxContext();

  return (
    <Wrap if={inPopover} with={SearchComboboxPopoverContent}>
      <CommandList
        data-state={listVisible ? 'open' : 'closed'}
        className={clsx(
          comboboxList,
          inPopover && comboboxPopoverList,
          className,
        )}
        {...props}
      />
    </Wrap>
  );
};
